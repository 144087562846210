import React, { useState, useEffect } from 'react';
import '../Styles/CampamentoInfo.css';

import videoBackground from '../BackgroundBlackVideo.mp4';

const CampamentoInfo = () => {
    const campStartDate = new Date('2025-01-16T00:00:00');
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    function calculateTimeLeft() {
        const difference = campStartDate - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return timeLeft;
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="fullscreen-container">
            <video className="video-background" autoPlay loop muted playsInline>
                <source src={videoBackground} type="video/mp4" />
                Tu navegador no soporta el elemento de video.
            </video>

            <div className="content-wrapper">
                <div className="info-section">
                    <h1>Campamento G4:7</h1>
                    <p>Únete a nosotros del 16 al 18 de enero para vivir una experiencia inolvidable de fe, diversión y comunidad.</p>
                    <div className="location">
                        <h3>Ubicación: Centro Victoria Morazán, Pérez Zeledón</h3>
                    </div>

                </div>

                <img className="camp-image" src={require('../a05631c9-43fd-4d1f-9b12-0d8a71c27011.jpg')} alt="Imagen del Campamento" />

                <div className="contact-number">Contacto: +506 8516-2552</div>

                <div className="countdown">
                    <h2>Faltan:</h2>
                    <p>{timeLeft.days} Días {timeLeft.hours} Horas {timeLeft.minutes} Minutos {timeLeft.seconds} Segundos</p>
                </div>
            </div>

        </div>
    );
};

export default CampamentoInfo;

import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import '../Styles/CarouselSection.css'; // Ajusta la ruta según la ubicación real del archivo CSS
import BackgroundWhiteVideo from '../BackgroundWhiteVideo.mp4'; // Importa el video BackgroundWhiteVideo desde la carpeta src
import Aniversario1 from '../Aniversario1.jpg';
import Aniversario2 from '../Aniversario2.jpg';
import Aniversario3 from '../Aniversario3.jpg';
import Aniversario4 from '../Aniversario4.jpg';
import Aniversario5 from '../Aniversario5.jpg';
import Aniversario6 from '../Aniversario6.jpg';
import Aniversario7 from '../Aniversario7.jpg';
import Aniversario8 from '../Aniversario8.jpg';

const CarouselSection = () => {
    const imgStyle = {
        width: '100%', // Ajusta el ancho de las imágenes al 100% del contenedor
        height: 'auto', // Mantiene la proporción de la imagen
        maxHeight: '900px', // Establece una altura máxima si es necesario
        objectFit: 'cover' // Ajusta la imagen para cubrir el contenedor
    };

    return (
        <section  id="carousel-section" className="CarrouselSection">
            <video className="VideoBackground" autoPlay loop muted playsInline>
                <source src={BackgroundWhiteVideo} type="video/mp4" />
                Tu navegador no soporta el elemento de video.
            </video>
            
            <Carousel>
                <div>
                    <img src={Aniversario1} alt="Actividad 1" style={imgStyle} />
                    <p className="legend">Aniversario 45</p>
                </div>
                <div>
                    <img src={Aniversario2} alt="Actividad 2" style={imgStyle} />
                    <p className="legend">Aniversario 45</p>
                </div>
                <div>
                    <img src={Aniversario3} alt="Actividad 3" style={imgStyle} />
                    <p className="legend">Aniversario 45</p>
                </div>
                <div>
                    <img src={Aniversario4} alt="Actividad 4" style={imgStyle} />
                    <p className="legend">Aniversario 45</p>
                </div>
                <div>
                    <img src={Aniversario5} alt="Actividad 5" style={imgStyle} />
                    <p className="legend">Aniversario 45</p>
                </div>
                <div>
                    <img src={Aniversario6} alt="Actividad 6" style={imgStyle} />
                    <p className="legend">Aniversario 45</p>
                </div>
                <div>
                    <img src={Aniversario7} alt="Actividad 7" style={imgStyle} />
                    <p className="legend">Aniversario 45</p>
                </div>
                <div>
                    <img src={Aniversario8} alt="Actividad 8" style={imgStyle} />
                    <p className="legend">Aniversario 45</p>
                </div>
            </Carousel>
        </section>
    );
};

export default CarouselSection;

import React from 'react';
import '../Styles/VideoSection.css'; // Ajusta la ruta según la ubicación real del archivo CSS
import RevoVideo from '../Revo.mp4'; // Importa el video Revo.mp4 desde la carpeta src
import BackgroundWhiteVideo from '../BackgroundWhiteVideo.mp4'; // Importa el video BackgroundWhiteVideo desde la carpeta src
import InstagramLogo from '../instagram-logo.png'
import FacebookLogo from '../facebook-logo.png'
import TiktokLogo from '../tiktok-logo.png'


const VideoSection = () => {
  return (
    <section id="video-section" className="VideoSection">
      <video className="VideoBackground" autoPlay loop muted playsInline>
        <source src={BackgroundWhiteVideo} type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>
      <div className="VideoContent">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', padding: '20px' }}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <div style={{ flex: 1, padding: '20px' }}>
              <p>¿Quieres ser parte de una generación que Revolucione la juventud?</p>
            </div>
            <div style={{ flex: 1 }}>
              <video width="1000" controls autoPlay loop>
                <source src={RevoVideo} type="video/mp4" />
                Tu navegador no soporta el elemento de video.
              </video>
            </div>
            <div style={{ flex: 1, padding: '20px' }}>
              <p>Todos los Domingos a las 5:00PM
                Te estamos esperando! 
              </p>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <p>Redes:</p>
            <a href="https://www.instagram.com/revolucion_victoria_/" target="_blank" rel="noopener noreferrer" style={{ margin: '45px 10px' }}>
              <img src={InstagramLogo} alt="Instagram" width="50" height="50" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100095433813067" target="_blank" rel="noopener noreferrer" style={{ margin: '45px 10px' }}>
              <img src={FacebookLogo} alt="Facebook" width="50" height="50" />
            </a>
            <a href="https://www.tiktok.com/@revolucion_victoria?lang=esface" target="_blank" rel="noopener noreferrer" style={{ margin: '45px 10px' }}>
              <img src={TiktokLogo} alt="TikTok" width="50" height="50" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;

import React, { useState, useEffect } from 'react';
import '../Styles/MeetingSection.css'; // Ajusta la ruta según la ubicación real del archivo CSS
import videoBackground from '../BackgroundBlackVideo.mp4'; // Ajusta la ruta según la ubicación real del video

const MeetingsSection = () => {
  const calculateTimeLeft = () => {
    const difference = +new Date("2025-01-16T17:00:00") - +new Date(); // Ajusta la fecha y hora de la próxima actividad
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <section id="meeting-section" className="MeetingSection">
      <video autoPlay loop muted playsInline>
        <source src={videoBackground} type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <p>Todos los Domingos a las 5:00pm</p>
        <p>Centro Victoria, San José, San Isidro de El General</p>
      </div>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3936.2928290273094!2d-83.70586998825631!3d9.395677983100247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa14eee5d37294f%3A0x45584403d6eb7e70!2sCentro%20Victoria!5e0!3m2!1ses!2scr!4v1720063991750!5m2!1ses!2scr"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Google Maps Location"
        ></iframe>
      </div>
      <div style={{ position: 'relative', zIndex: 1 }}>
        <h2>Próxima actividad:
          Campamento G4:7
        </h2>
        <div className="countdown">
          {timeLeft.days !== undefined ? (
            <>
              <span>{timeLeft.days}d</span> : <span>{timeLeft.hours}h</span> : <span>{timeLeft.minutes}m</span> : <span>{timeLeft.seconds}s</span>
            </>
          ) : (
            <span>¡La actividad ha comenzado!</span>
          )}
        </div>
      </div>
    </section>
  );
};

export default MeetingsSection;

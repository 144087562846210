import React from 'react';
import '../Styles/Header.css';
import HeaderBackground from '../HeaderBackground.jpg'; // Asegúrate de ajustar la ruta según la ubicación real de la imagen

const Header = () => {
  return (
    <header style={{ backgroundImage: `url(${HeaderBackground})` }}>
      <h1>Revolución Victoria</h1>
      <nav>
        <a href="#video-section">Inicio</a>
        <a href="#CampamentoInfo-section">Campamento</a>
        <a href="#meeting-section">Reuniones</a>
        <a href="#carousel-section">Actividades</a>
      
      </nav>
    </header>
  );
};

export default Header;

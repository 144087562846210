import React from 'react'; // Importa la biblioteca React.
import './App.css'; // Importa los estilos desde App.css.
import Header from './components/Header';
import VideoSection from './components/VideoSection'; // Importa el componente VideoSection.
import MeetingsSection from './components/MeetingSection'; // Importa el componente MeetingsSection.
import CarouselSection from './components/CarouselSection'; // Importa el componente CarouselSection.
import CampamentoInfo from './components/CampamentoInfo'; // Importa el componente CampamentoInfo

function App() { // Define el componente App.
  return (
    <div className="App"> 
      <Header/>
      <CampamentoInfo /> {/* Agrega el componente CampamentoInfo */}
      <VideoSection /> 
      <MeetingsSection /> 
      <CarouselSection /> 
      

    </div>
  );
}

export default App; // Exporta el componente App como el componente predeterminado del archivo.
